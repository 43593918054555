import React, { FC } from "react";
import { Box } from "@mui/material";


type LoomVideoProps = {
  src: string
}

export const LoomVideo:FC<LoomVideoProps> = ({ src }) => {

  return (
    <Box position="relative" paddingBottom="64.78494623655914%">
      {/* <div style="position: relative; padding-bottom: 64.78494623655914%; height: 0;"><iframe src="https://www.loom.com/embed/6d681fb832fd4bad893b6c24778c006a" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div> */}
      <iframe src={src} width="100%" height="100%" style={{
        position: "absolute"
      }}/>
    </Box>
  );
};

export default LoomVideo;
