import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

import StandardFooter from "../components/reviewskew/standardpage/StandardFooter"
import LandingHero from "../components/reviewskew/landing/LandingHero";
import StandardHeader from "../components/reviewskew/standardpage/StandardHeader";

import FavortieBrands from "../components/reviewskew/landing/FavoriteBrands";
import LoomVideo from "../components/reviewskew/landing/LoomVideo";
import Overview from "../components/reviewskew/landing/Overview";


const ExtensionHomePage = () => {
  return (
    <Box>
      <Helmet title="Review Skew - AI Product Review Summaries" />

      <StandardHeader />
      
      <LandingHero>
        <Grid item xs={12} lg={5.5}>
          <Overview />
        </Grid>

        <Grid item xs={1} height={40} />
        
        <Grid item xs={12} lg={5.5}>
          <LoomVideo src="https://www.loom.com/embed/6d681fb832fd4bad893b6c24778c006a" />
        </Grid>
      </LandingHero>

      <Box padding={8}>
        <FavortieBrands />
      </Box>

      <StandardFooter />
    </Box>
  );
};

export default ExtensionHomePage;
